import Home from '../modules/home/Home'
import About from '../modules/about/About'
import Store from '../modules/Store'

const config = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/store/:id',
    component: Store
  }
]

export default config
