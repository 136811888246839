import React from 'react';
import PropTypes from 'prop-types';

const Store = (props) => {
  console.log('props', props.match.params.id)
  return (
    <React.Fragment>
      <div>props.match.params.id</div>
      <div>{props.match.params.id}</div>
    </React.Fragment>
  )
}

Store.propTypes = {
  
}

export default Store;
